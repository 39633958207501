import styled from "styled-components";

export const Container = styled.footer<{ $bgColor: string }>`
  background-color: ${({ $bgColor }) => $bgColor};
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  width: 100%;
`;

export const County = styled.div`
  display: flex;
  align-items: center;
  padding: 1.5rem 0.5rem 0 0.5rem;
`;

export const Title = styled.p`
  font-family: Fjalla One;
  text-transform: uppercase;
  letter-spacing: 0.05rem;
  padding-left: 1rem;
`;

export const Links = styled.div`
  color: white;
  display: flex;
  padding: 0 0.5rem;

  & > a {
    padding: 4px 12px;

    &:hover {
      color: white;
    }
  }

  & > a:not(:last-child) {
    border-right: 1px solid white;
  }
`;

export const CountyInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  gap: 2px;
  text-align: center;
  padding: 0 0.5rem;

  & > *:hover {
    color: white;
  }
`;

export const PoweredBy = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  background-color: black;
  width: 100%;
`;
